import { Fragment, useEffect, useState } from "react";
import BreadcrumbNavigation from "../components/BreadcrumbNavigation";
import NavigationAppBar from "../components/NavigationAppBar";
import BarGraph from "../components/BarGraph";
import PieGraph from "../components/PieGraph";
import LineGraph from "../components/LineGraph";
import { Autocomplete, TextField } from "@mui/material";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken } from "../axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../store/slices/userSlice";
import { MetaDataLoader } from "../components/ComponentLoader";
import { selectDiseaseCategory, selectDiseaseType, selectDiseaseTypeList, updateDiseaseCategory, updateDiseaseType, updateDiseaseTypeList } from "../store/slices/diseaseCategorySlice";

interface MetaData {
    [key: string]: { [key: string]: string | number }[];
}

function MetadataVisualization() {
    const [chartType, setChartType] = useState<string | null>("Pie Chart");
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    const [metaData, setMetaData] = useState<MetaData>({});
    const [filterWidth, setFilterWidth] = useState(49.5);

    useEffect(() => {
        function handleWindowResize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize',handleWindowResize);
        return () => {
            window.removeEventListener('resize',handleWindowResize);
        };
    },[]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedUser = useSelector(selectUser);
    const [filterApplied, setFilterApplied] = useState(false);
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const diseaseType = useSelector(selectDiseaseType);
    const diseaseTypeList = useSelector(selectDiseaseTypeList);
    const handleCategoryChange = (selectedDiseaseCategory: string | null) => {
        if (typeof selectedDiseaseCategory === 'string') {
            dispatch(updateDiseaseCategory(selectedDiseaseCategory));
        } else { dispatch(updateDiseaseCategory("Inflammatory Bowel Disease")); }
    };
    const fetchMetaData = async () => {
        let query = `/api/meta?DiseaseCategory=${diseaseCategory}`;
        try {
            if (diseaseCategory !== "Inflammatory Bowel Disease") {
                query += `&DiseaseType=${diseaseType}`;
            }
            const response = await axiosConfig.get(query);
            setMetaData(response.data.data);
            setFilterApplied(true);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    setMetaData((await axiosConfig.get(query)).data.data);
                    setFilterApplied(true);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    const fetchDiseaseType = async () => {
        try {
            const response = await axiosConfig.get(`/api/disease_category?DiseaseCategory=${diseaseCategory}`);
            if (!((response.data.data)[diseaseCategory].includes(diseaseType))) {
                dispatch(updateDiseaseType(diseaseCategory === "Inflammatory Bowel Disease" ? "" : (response.data.data)[diseaseCategory][0]));
                dispatch(updateDiseaseTypeList((response.data.data)[diseaseCategory]));
            }
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(`/api/disease_category?DiseaseCategory=${diseaseCategory}`); 
                    dispatch(updateDiseaseType(diseaseCategory === "Inflammatory Bowel Disease" ? "" : (refreshedResponse.data.data)[diseaseCategory][0]));
                    dispatch(updateDiseaseTypeList((refreshedResponse.data.data)[diseaseCategory]));
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    useEffect(() => {
        setFilterApplied(false);
        setFilterWidth(diseaseCategory === "Inflammatory Bowel Disease" ? 99/2 : 99/3);
        fetchDiseaseType();
    // eslint-disable-next-line
    }, [diseaseCategory]);

    useEffect(() => {
        setFilterApplied(false);
        if (diseaseType !== null) {
            fetchMetaData();
        }
    // eslint-disable-next-line
    }, [diseaseType]);

    const barLeft = [0.050, 0.022, 0.022, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.017, 0.047];
    const lineLeft = [0.060, 0.042, 0.042, 0.017, 0.017, 0.017, 0.035, 0.017, 0.017, 0.032, 0.077, 0.017, 0.017, 0.017, 0.027, 0.054, 0.045, 0.068, 0.017, 0.017, 0.017, 0.022, 0.047];
    const graphConfig = [
        { onX: "Cell Type", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 150, 
            render: () => metaData.CellTypeVsAccessionCount.map(item => { return { "Number Of Datasets": item["GSEAccessionIDs"], "Cell Type": item["CellType"] }}) },
        { onX: "Sequencing Platform", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 70, 
            render: () => metaData.SequencingPlatformVsAccessionCount.map(item => { return { "Sequencing Platform": item["SequencingPlatform"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
        { onX: "Sequencer", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 70, 
            render: () => metaData.SequencerVsAccessionCount.map(item => { return { "Sequencer": item["Sequencer"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
        { onX: "Publication Year", onY: "Number Of Publications", curation: "study", dontRender: [], height: 10, 
            render: () => metaData.YearVsPublications.map(item => { return { "Number Of Publications": item["NumberOfPublications"], "Publication Year": item["PublicationYear"] }}) },
        { onX: "Last Update Year", onY: "Number Of Last Updates", curation: "study", dontRender: [], height: 10, 
            render: () => metaData.YearVsLastUpdates.map(item => { return { "Number Of Last Updates": item["NumberOfLastUpdates"], "Last Update Year": item["LastUpdateYear"] }}) },
        { onX: "Age", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 80, 
            render: () => metaData.AgeVsAccession.map(item => { return { "Number Of Datasets": item["GSEAccessionIDs"], "Age": item["Age"] }}) },
        { onX: "Contributor", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 80, 
            render: () => metaData.ContributorVsAccessionCount.map(item => { return { "Number Of Datasets": item["GSEAccessionIDs"], "Contributor": item["Contributor"] }}) },
        { onX: "GSE Accession IDs", onY: "Number Of Cells", curation: "experiment", dontRender: [], height: 30, 
            render: () => metaData.AccessionVsCells.map(item => { return { "GSE Accession IDs": item["GSEAccessionIDs"], "Number Of Cells": item["NumberOfCells"] }}) },
        { onX: "Cell Types", onY: "Number Of Cells", curation: "experiment", dontRender: [], height: 30, 
            render: () => metaData.CellsVsCellTypes.map(item => { return { "GSE Accession IDs": item["GSEAccessionIDs"], "Cell Types": item["CellTypes"], "Number Of Cells": item["NumberOfCells"] }}) },
        { onX: "Library Processing Protocol", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 130, 
            render: () => metaData.LibraryProcessingProtocolVsAccessionCount.map(item => { return { "Library Processing Protocol": item["LibraryProcessingProtocol"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
        { onX: "Cell Sorting Technique", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 130, 
            render: () => metaData.CellSortingTechniqueVsAccessionCount.map(item => { return { "Cell Sorting Technique": item["CellSortingTechnique"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
        { onX: "Gender", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: 50, 
            render: () => metaData.GenderVsAccession.map(item => { return { "Gender": item["Gender"], "Number Of Datasets": item["GSEAccessionIDs"], "GSE List": item["list_of_geoaccession"] }}) },
        { onX: "Library Selection", onY: "Number Of Samples", curation: "sample", dontRender: [], height: 50, 
            render: () => metaData.LibrarySelectionVsSampleAccessionCount.map(item => { return { "Number Of Samples": item["GSEAccessionIDs"], "Library Selection": item["LibrarySelection"] }}) },
        { onX: "Library Preparation Platform", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: diseaseCategory === "Inflammatory Bowel Disease" ? 160 : (diseaseType === "Alzheimer's Disease") ? 140 : 50, 
            render: () => metaData.LibraryPreparationPlatformVsAccessionCount.map(item => { return { "Library Preparation Platform": item["LibraryPreparationPlatform"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
        { onX: "Sequence Data Processor", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: diseaseCategory === "Inflammatory Bowel Disease" ? 160 : 90, 
            render: () => metaData.SequenceDataProcessorVsAccessionCount.map(item => { return { "Sequence Data Processor": item["SequenceDataProcessor"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
        { onX: "Technology Type", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: diseaseType === "Alzheimer's Disease" ? 140 : 90, 
            render: () => metaData.TechnologyTypeVsAccessionCount.map(item => { return { "Technology Type": item["TechnologyType"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
        { onX: "Library Source", onY: "Number Of Samples", curation: "sample", dontRender: [], height: 90, 
            render: () => metaData.LibrarySourceVsSampleAccessionCount.map(item => { return { "Number Of Samples": item["GSEAccessionIDs"], "Library Source": item["LibrarySource"] }}) },
        { onX: "Sample Acquisition Method", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: diseaseCategory === "Inflammatory Bowel Disease" ? 30 : (diseaseType === "Alzheimer's Disease") ? 100 : 90, 
            render: () => metaData.SampleAcquisitionMethodVsAccessionCount.map(item => { return { "Number Of Datasets": item["GSEAccessionIDs"], "Sample Acquisition Method": item["SampleAcquisitionMethod"] }}) },
        { onX: "Extracted Molecule", onY: "Number Of Samples", curation: "sample", dontRender: [], height: 30, 
            render: () => metaData.ExtractedMoleculeVsSampleAccessionCount.map(item => { return { "Number Of Samples": item["GSEAccessionIDs"], "Extracted Molecule": item["ExtractedMolecule"] }}) },
        { onX: "Disease Status", onY: "Number Of Samples", curation: "sample", dontRender: ['Neurodegenerative Disease'], height: 30, 
            render: () => metaData.DiseaseStatusVsSampleAccessionCount.map(item => { return { "Number Of Samples": item["GSEAccessionIDs"], "Disease Status": item["DiseaseStatus"] }}) },
        { onX: "Library Strategy", onY: "Number Of Samples", curation: "sample", dontRender: [], height: 30, 
            render: () => metaData.LibraryStrategyVsSampleAccessionCount.map(item => { return { "Number Of Samples": item["GSEAccessionIDs"], "Library Strategy": item["LibraryStrategy"] }}) },
        { onX: "Disease", onY: "Number Of Datasets", curation: "experiment", dontRender: ['Neurodegenerative Disease'], height: 64, 
            render: () => metaData.DiseaseVsAccessionCount.map(item => { return { "Number Of Datasets": item["GSEAccessionIDs"], "Disease": item["Disease"] }}) },
        { onX: "Sequence Data Aligner", onY: "Number Of Datasets", curation: "experiment", dontRender: [], height: diseaseCategory === "Inflammatory Bowel Disease" ? 190 : (diseaseType === "Alzheimer's Disease") ? 120 : 90, 
            render: () => metaData.SequenceDataAlignerVsAccessionCount.map(item => { return { "Sequence Data Aligner": item["SequenceDataAligner"], "Number Of Datasets": item["GSEAccessionIDs"] }}) },
    ];

    const renderedCharts = () => {
        if (diseaseCategory === "Inflammatory Bowel Disease" && !Object.keys(metaData).includes("DiseaseVsAccessionCount")) return null;
        if (chartType === "Bar Chart") {
            return (
                <Fragment>
                    {graphConfig.map((graph, index) => (!(graph.dontRender as string[]).includes(diseaseCategory) && 
                        <BarGraph key={index} data={graph.render()} width={size[0]} height={(size[1]*0.4610)+graph.height}
                        margin={{ top: size[1]*0.051, right: size[0]*0.016, bottom: size[1]*((graph.height/1000)+0.005), left: size[0]*barLeft[index] }}
                        onX={graph.onX} onY={graph.onY} curation={graph.curation}/>
                    ))}
                </Fragment>
            );
        } else if (chartType === "Line Chart") {
            return (
                <Fragment>
                    {graphConfig.map((graph, index) => (!(graph.dontRender as string[]).includes(diseaseCategory) && 
                        <LineGraph key={index} data={graph.render()} width={size[0]} height={(size[1]*0.4610)+graph.height}
                        margin={{ top: size[1]*0.051, right: size[0]*0.016, bottom: size[1]*((graph.height/1000)+0.005), left: size[0]*lineLeft[index] }}
                        onX={graph.onX} onY={graph.onY} curation={graph.curation}/>
                    ))}
                </Fragment>
            );
        } else if (chartType === "Pie Chart") {
            return (
                <Fragment>
                    {graphConfig.map((graph, index) => (!(graph.dontRender as string[]).includes(diseaseCategory) && ![0,7,8].includes(index) &&
                        <PieGraph key={index} data={graph.render().map((object: any) => {
                            object['name'] = object[graph.onX]; delete object[graph.onX];
                            return object;
                        })} width={size[0]} height={size[1]*1.3} radius="68%" onX={graph.onX} onY={graph.onY} curation={graph.curation}/>                    ))}
                </Fragment>
            );
        }
    }
    return (
        <Fragment>
            <div><NavigationAppBar/></div>
            <div style={{ borderBottom: '1px solid #ccc' }}><BreadcrumbNavigation breadcrumbs={[{path:'/', text:'Home'},{path:'/metadata-visualization', text:'Metadata Visualization' },]}/></div>
            <div style={{ display: 'flex' }}>
                <Autocomplete id="charts"
                    options={["Bar Chart", "Line Chart", "Pie Chart"]} sx={{width: String(filterWidth)+'%', paddingTop: 1.5, paddingBottom: 1}} 
                    renderInput={(params) => <TextField {...params} label="Select Chart Type"/>}
                    value={chartType} onChange={(event: any, newValue: string | null) =>  setChartType(typeof newValue === 'string' ? newValue : 'Bar Chart') }/>
                <Autocomplete id="diseaseCategory"
                    options={["Inflammatory Bowel Disease", "Neurodegenerative Disease"]} sx={{width: String(filterWidth)+'%', paddingTop: 1.5, paddingBottom: 1, paddingLeft: 1}} 
                    renderInput={(params) => <TextField {...params} label="Select Disease Category"/>}
                    value={diseaseCategory} onChange={(event: any, newValue: string | null) =>  handleCategoryChange(newValue) }/>
                {diseaseCategory !== "Inflammatory Bowel Disease" && <Autocomplete id="diseaseType"
                    options={diseaseTypeList} sx={{width: String(filterWidth)+'%', paddingTop: 1.5, paddingBottom: 1, paddingLeft: 1}} 
                    renderInput={(params) => <TextField {...params} label="Select Disease Type"/>}
                    value={diseaseType} onChange={(event: any, newValue: string | null) =>  { if (newValue !== null) dispatch(updateDiseaseType(newValue)); } }/>}
            </div>
            {chartType !== null && <div className="metadata">
                {filterApplied ? renderedCharts() : 
                    <div style={{marginTop: '-70px'}}><MetaDataLoader/></div>}
            </div>}
        </Fragment>
    );
}

export default MetadataVisualization;