import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import { selectSearchKey } from "../store/slices/searchSlice";
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import { selectFilters, updateFilters } from "../store/slices/filtersSlice";
import NavigationAppBar from "./NavigationAppBar";
import BreadcrumbNavigation from "./BreadcrumbNavigation";
import SelectDiseaseCategory from "./SelectDiseaseCategory";
import TableDownloadButton from "./TableDownloadButton";
import { Button } from "@mui/material";
import RowSelectionDialog from "./RowSelectionDialog";
import SideBar from "./SideBar";
import DataTable from "./DataTable";
import DataTableLoader from "./ComponentLoader";

interface ColumnConfig {
    Id: string;
    label: string;
    render: (Datasets: any) => any;
    isVisible: boolean;
    width: number;
}

interface TableProps {
    config: ColumnConfig[];
    curating: string;
    navButtons: string[];
    width: number;
    rowkey: string;
}

function CurationTable({ config, curating, navButtons, width, rowkey }: TableProps) {
    const [rows, setRows] = useState([]);
    const [columns, updateColumns] = useState(config);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalRows, setTotalRows] = useState(0);
    const searchKey = useSelector(selectSearchKey);
    const [filterApplied, setFilterApplied] = useState(false);
    const diseaseCategory = useSelector(selectDiseaseCategory);
    let selectedFilters = useSelector(selectFilters);
    const diseaseType = useSelector(selectDiseaseType);

    useEffect(() => {
        updateColumns(config);
    }, [config]);

    const fetchCurationTable = async (page: number = 1, perPage: number = 15) => {
        let query = (searchKey.length > 0) ? `/api/${curating}_search?page=${page}&per_page=${perPage}&search_string=${encodeURIComponent(searchKey)}&DiseaseCategory=${diseaseCategory}&` : 
                    `/api/${curating}?page=${page}&per_page=${perPage}&DiseaseCategory=${diseaseCategory}&`;
        if (Object.values(selectedFilters).some((value) => value.length > 0)) {
            Object.keys(selectedFilters).forEach(filter => {
                selectedFilters[filter].forEach(value => {
                    query += `${filter}=${encodeURIComponent(value)}&`;
                });
            });
        }
        query = query.slice(0,-1);
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        try {
            const response = await axiosConfig.get(query);
            setTotalRows(response.data.no_of_records);
            setRows(response.data.data);
            setFilterApplied(true);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(query);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    setRows(refreshedResponse.data.data);
                    setFilterApplied(true);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    useEffect(() => {
        setFilterApplied(false);
        if (diseaseType !== null) {
            fetchCurationTable();
        }
    // eslint-disable-next-line
    }, [searchKey, diseaseType]);

    const handleChange = (label: string) => {
        const updatedColumns = columns.map((column) => {
            if (label !== column.label) {
                return column;
            }
            return { ...column, isVisible: !column.isVisible };
        });
        updateColumns(updatedColumns);
    };

    const handleFilteredRows = (filteredRows: any) => {
        if (filteredRows[0] === 'loader') {
            setFilterApplied(false);
            setRows([]);
            return;
        }
        setFilterApplied(true);
        setRows(filteredRows);
    }

    const [selectedRows, setSelectedRows] = useState<string[] | object>(curating === 'sample' ? {} : []);
    const handleNavigation = (newSelectedRows: string[] | object) => {
        setSelectedRows(newSelectedRows);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const navigateToTable = (curation: string) => {
        const checkedRows = (curating === 'sample') ? Array.from(new Set(Object.values(selectedRows))) : selectedRows;
        if ((checkedRows as string[]).length === 0) {
            setOpenDialog(true);
            return;
        }
        setRows([]);
        setFilterApplied(false);
        selectedFilters = { ...selectedFilters, "GEOAccession": [] };
        (checkedRows as string[]).forEach((GEOAccession) => {
            selectedFilters = { ...selectedFilters, "GEOAccession": [...selectedFilters['GEOAccession'], GEOAccession] };
        });
        dispatch(updateFilters(selectedFilters));
        navigate((curation === "Sample") ? '/sample-table' : (curation === "Study") ? '/study-table' : '/experiment-table');
    };

    return (
        <Fragment>
            <div><NavigationAppBar includeSearch={true}/></div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
                <div style={{ display: 'flex', whiteSpace: 'pre', alignItems: 'center' }}>
                    <BreadcrumbNavigation breadcrumbs={[{path: '/', text: 'Home'}, {path: `/${curating}-table`, text: `${curating[0].toUpperCase() + curating.slice(1)} Level Curation`}]}/>
                    <SelectDiseaseCategory setFilterApplied={setFilterApplied}/>
                </div>
                <div style={{ whiteSpace: 'pre', display: 'flex' }}>
                    <TableDownloadButton setOpenDialog={setOpenDialog} selectedRows={selectedRows} curation={curating}/>
                    <Button onClick={() => navigateToTable(navButtons[0])} size="medium" variant="contained" style={{ color: 'black', backgroundColor: '#f1f3f6', margin: '5px', textTransform: 'none', height: '30px', padding: '15px' }}><b>{navButtons[0]} Level Curation</b></Button>
                    <Button onClick={() => navigateToTable(navButtons[1])} size="medium" variant="contained" style={{ color: 'black', backgroundColor: '#f1f3f6', margin: '5px', textTransform: 'none', height: '30px', padding: '15px' }}><b>{navButtons[1]} Level Curation</b></Button>
                    <RowSelectionDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
                </div>
            </div>
            <div className="container">
                <div className="sidebar">
                    <SideBar setTotalRows={setTotalRows} columns={columns} handleChange={handleChange} handleFilteredRows={handleFilteredRows} curation={curating}/>
                </div>
                <div className="table">
                    { filterApplied ? <DataTable fetchFunction={fetchCurationTable} totalRows={totalRows} table={rows} columns={columns.filter((column) => column.isVisible)} width={rows.length === 0 ? 0 : width} rowkey={rowkey} selectedRows={selectedRows} handleNavigation={handleNavigation} apiParams={null} curation={curating}/> : 
                        <DataTableLoader/> }
                </div>
            </div>
        </Fragment>
    );
}

export default CurationTable;